import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import './Policies.scss';
import './TermsAndConditions.scss';





export default (props) => {
  let currentYear = new Date().getFullYear();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const [isBeforeTargetDate, setIsBeforeTargetDate] = useState(true);

  const tableB = useRef(null);

  const selection23 = useRef(null);
  const multipackRef24 = useRef(null)
  
  const scrollToSelection23 = () => scrollToRef(selection23);
  const scrollToMultipack24 = () => scrollToRef(multipackRef24);

  
    useEffect(() => {
      const targetDate = new Date('2024-12-31T23:59:00Z');
      const currentDate = new Date();
  
      if (currentDate > targetDate) 
      {
        setIsBeforeTargetDate(false);
      }
    }, []); 


  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Cadbury</title>
    </Helmet>
    <section className="layout-general layout-wrapper tandc">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h2 type-h2--gold">T&C<span className="lowercase">s</span></h1>
        <div className="policies">
          <p>Last updated on: 20/12/2024</p>
          <p><strong>Please note:</strong> After six years in operation, The Bear Grylls Adventure will close at the beginning of December. This has been a very difficult decision and one that was made only after careful consideration. Our priority now is looking after our team members and animals throughout this transition.  Over the coming weeks, we will continue to provide world-class animal care as we prepare to transfer our animals to suitable new homes, including to our nearby aquarium the National SEA LIFE Centre Birmingham and other UK SEA LIFE sites. Our team of welfare specialists at SEA LIFE will oversee the transition process to ensure it runs as smoothly as possible. We are proud of the positive impact The Bear Grylls Adventure has had on thousands of children and families that have visited over the last six years, showing guests first-hand the power of immersive activity in escaping the ordinary, and how adventure is for everyone. We want to thank our amazing team and guests who have made this time so memorable.</p>
          
          { isBeforeTargetDate &&
          <nav className='tandc__links'>
              <ul>
              
              <li><a onClick={scrollToMultipack24}>Cadbury Big Ticket Savings 2024 Chocolate Multipacks, Biscuits, Selections Boxes and Seasonal Biscuits</a></li>
              
                <li><a onClick={scrollToSelection23}>Cadbury Big Ticket Savings 2023 Selection Boxes And Seasonal Biscuits</a></li>
              
            </ul>
          </nav>
          }
          <div className='tandc__disclaimer'>
            <p>
              These terms and conditions may be updated from time to time. Visit this page before booking to ensure you have the latest terms and conditions.  Ticket availability limitations (or even attraction closures) apply; safety measures or operational changes may be required; for more details visit the website of the attraction you wish to visit for the latest information.
            </p>
            
          </div>

          <br />
           {/*Cadbury choc and bisuits multipack 2023*/}
           <div ref={multipackRef24} className='type-h3 type-h3--gold'>
            TERMS AND CONDITIONS - Cadbury Big Ticket Savings 2024 Chocolate Multipacks, Biscuits, Selections Boxes and Seasonal Biscuit
          </div>
          <br />
          <p>
            <strong><u>TO REDEEM YOUR OFFER, PRE-BOOKING ONLINE IS ESSENTIAL:</u></strong><br />
            <strong>STEP 1:</strong> Go to <a href='https://fun.cadbury.co.uk/' target='_blank' rel='noopener noreferrer'>www.fun.cadbury.co.uk</a> and enter your barcode and batch code found on the reverse of your promotional pack (full details of where to locate these can be found online), along with your name and email address** and enter required details. </p>
          <p><strong>STEP 2:</strong> Once your pack(s) have been validated you will be emailed your unique code(s) and booking link.   </p>
          <p><strong>STEP 3:</strong> Select your attraction <u>and visit date, according to the discount level preferred (from those available)</u>, enter your unique code(s) when prompted and complete the booking journey and pay for your entry tickets. Each code can only be used once. </p>
          <p><strong>STEP 4:</strong> Print/ download your entry tickets and head straight to the attraction on your booked date of visit, scan your tickets (on your phone or a print-out) under the barcode readers to enter the attraction. </p>
          <p><i>**Please note that only one offer /unique code can be used per person. </i></p>
          <div className="type-h3 type-h3--gold">Full Terms and Conditions:</div>
          <ol typeof='1'>
            <li>
              <p>
                 This offer gives 10-50% off for up to 4 Adult and Child online priced entry tickets at selected Merlin Entertainments Attractions. The discount depends on the attraction and period of time chosen (super off peak, off peak, peak, super peak), from a minimum of 10% in super peak, up to a maximum of 50% on select super off peak dates. This offer is valid until 30th June 2025. Pre-booking online is essential. Promotional tickets are subject to availability and must be purchased no later than 23:00 the night before your chosen date of visit. Promotional tickets for Cadbury's customers are limited in numbers for each day the attraction is open, subject to a minimum number allocated to every day each attraction opens, therefore customers are advised to plan ahead and book early (see full T&Cs, below, for details). Book early to secure popular dates e.g. weekends/ school holidays.
              </p>
            </li>
            <li>
              <p>
                The offer is based on the online priced entry tickets at each attraction; such prices are subject to change.  
              </p>
            </li>
            <li>
              <p>
                Offer valid at the following participating attractions; Alton Towers Resort, Chessington World of Adventures Resort, Thorpe Park, The LEGOLAND&#174; Windsor Resort, Warwick Castle, Shrek's Adventure! London, Madame Tussauds (London or Blackpool), The Dungeons (London, York, Edinburgh or Blackpool Tower), The Blackpool Tower Eye, The Blackpool Tower Eye Circus, The Lastminute.com London Eye, LEGOLAND&#174; Discovery Centres (Manchester or Birmingham), SEA LIFE Aquariums & Centres (Birmingham, Great Yarmouth, Loch Lomond, Blackpool, London, Brighton, Manchester, Scarborough, Hunstanton and Weymouth SEA LIFE Adventure Park), Cadbury World.  
              </p>
            </li>
            <li>
              <p>
                Pre-booking this offer is essential via <a href='https://fun.cadbury.co.uk/' target='_blank' rel='noopener noreferrer'>www.fun.cadbury.co.uk</a>. Follow the steps outlined on the website, using the barcode and batch code number from the reverse of your pack online to enter. Booking is subject to attraction and ticket allocation availability at the time of booking - book early to secure popular dates, such as weekends, school and bank holidays. 
              </p>
            </li>
            <li>
              <p>
                A barcode and batch code from a promotional pack can only be used once. Only one offer /unique code can be used per person.
              </p>
            </li>
            <li>
              <p>
                This offer is not valid for use in conjunction with any other promotion, offer, reward/loyalty scheme or direct attraction booking. Offer is also not valid for redemption against pre-purchased tickets, group bookings and concession rate tickets including but not limited to; senior citizens, veterans, family tickets, students, merlin annual pass or season pass holders. 
              </p>
            </li>
            <li>
              <p>
                This offer is valid until <strong>30th June 2025</strong> at all participating attractions.  
              </p>
            </li>
            <li>
              <p>
                Merlin Entertainments may limit offer ticket allocations, subject to a minimum number allocated to every day each attraction is open. The minimum allocation of tickets during OFF PEAK, SUPER OFF PEAK, SUPER PEAK and PEAK dates (<span onClick={() => scrollToRef(tableB)} className='button--text-link'>*See table B</span> below for details of these dates) shall be as set out in table A below.  As these are minimum allocations, Merlin Entertainments may (at its sole discretion) from time to time make additional tickets available <u>for purchase at the relevant discount under this offer for particular attractions</u>, so you may wish to check back later if your preferred attraction and date combination are not available when you first attempt to book.  
              </p>
                <p><strong>Table A - Minimum Allocations by Attraction</strong></p>
                <table>
                  <tr>
                    <th>Attraction</th>
                    <th>Minimum allocation</th>
                  </tr>
                  <tr>
                    <td colspan="2" className='tandc__heading'>Super off peak</td>
                  </tr>
                  <tr>
                    <td>All participating attractions </td>
                    <td>Unlimited availability</td>
                  </tr>
                  <tr>
                    <td colspan="2" className='tandc__heading'>Off-peak</td>
                  </tr>
                  <tr>
                    <td>All participating attractions </td>
                    <td>Unlimited availability</td>
                  </tr>
                  <tr>
                    <td colspan="2" className='tandc__heading'>Peak</td>
                  </tr>
                  <tr>
                    <td>Alton Towers Resort</td>
                    <td>110 tickets per day </td>
                  </tr>
                  <tr>
                    <td>Chessington World of Adventures Resort</td>
                    <td>110 tickets per day</td>
                  </tr>
                  <tr>
                    <td>Thorpe Park</td>
                    <td>70 tickets per day </td>
                  </tr>
                  <tr>
                    <td>The LEGOLAND&#174; Windsor Resort </td>
                    <td>100 tickets per day </td>
                  </tr>
                  <tr>
                    <td>Warwick Castle</td>
                    <td>70 tickets per day</td>
                  </tr>
                  <tr>
                    <td>
                      Shrek's Adventure London,<br />
                      Madame Tussauds London, <br />
                      London Dungeons,<br />
                      SEA LIFE London Aquarium <br />
                      & the lastminute.com <br />
                      London Eye<br />
                    </td>
                    <td>125 tickets per day, per site</td>
                  </tr>
                  <tr>
                    <td>
                      SEA LIFE Centre Birmingham,<br />
                      Blackpool, Brighton, <br />
                      Great Yarmouth, Hunstanton, <br />
                      Loch Lomond, Manchester, <br />
                      Scarborough and Weymouth <br />
                    </td>
                    <td>75 tickets per day, per site</td>
                  </tr>
                  <tr>
                    <td>
                      LEGOLAND&#174; Discovery Centre Manchester & Birmingham, <br />
                      Blackpool Dungeons, <br />
                      Blackpool Tower Eye, <br />
                      Blackpool Tower Circus, <br />
                      Madame Tussauds Blackpool, <br />
                      Edinburgh Dungeons, <br />
                      York Dungeons.<br />
                    </td>
                    <td>35 tickets per day, per site</td>
                  </tr>
                  <tr>
                    <td colspan='2' className='tandc__heading'>Super peak</td>
                  </tr>
                  <tr>
                    <td>Alton Towers Resort</td>
                    <td>40 tickets per day </td>
                  </tr>
                  <tr>
                    <td>Chessington World of Adventures Resort</td>
                    <td>30 tickets per day</td>
                  </tr>
                  <tr>
                    <td>Thorpe Park</td>
                    <td>30 tickets per day </td>
                  </tr>
                  <tr>
                    <td>The LEGOLAND&#174; Windsor Resort </td>
                    <td>40 tickets per day </td>
                  </tr>
                  <tr>
                    <td>Warwick Castle</td>
                    <td>30 tickets per day</td>
                  </tr>
                  <tr>
                    <td>
                      Shrek's Adventure London,<br />
                      Madame Tussauds London, <br />
                      London Dungeons,<br />
                      SEA LIFE London Aquarium <br />
                      & the lastminute.com <br />
                      London Eye<br />
                    </td>
                    <td>125 tickets per day, per site</td>
                  </tr>
                  <tr>
                    <td>
                      SEA LIFE Centre Birmingham,<br />
                      Blackpool, Brighton, <br />
                      Great Yarmouth, Hunstanton, <br />
                      Loch Lomond, Manchester, <br />
                      Scarborough and Weymouth <br />
                    </td>
                    <td>75 tickets per day, per site</td>
                  </tr>
                  <tr>
                    <td>
                      LEGOLAND&#174; Discovery Centre Manchester & Birmingham, <br />
                      Blackpool Dungeons, <br />
                      Blackpool Tower Eye, <br />
                      Blackpool Tower Circus, <br />
                      Madame Tussauds Blackpool, <br />
                      Edinburgh Dungeons, <br />
                      York Dungeons.<br />
                    </td>
                    <td>35 tickets per day, per site</td>
                  </tr>
                </table>
                <p ref={tableB}><strong>Table B - OFF PEAK, SUPER OFF PEAK, PEAK and SUPER PEAK dates </strong></p>
                <div className='type-h3'>2024</div>
                <table >
                  <tr>
                    <th colspan='6'>Alton towers resort</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed Dates</td>
                  </tr>
                  <tr >
                    <td colspan='6'>
                      4-6, 11-21, 25-28 November<br />
                      2-5, 9-12, 22-28 December<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super Off-peak Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      15, 17-18, 23-25, 29 April <br />
                      1, 3, 7-9, May <br />
                      24, September <br />
                      1-2, 8, October <br />
                      22-24, 29-30 November  <br />
                      1, 6-8, 13-21 December  <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      7-12, 14, 16, 19, 21-22, 26-28, 30 April 	<br />
                      2, 4, 6, 10, 12-17, 19-23 May 	<br />
                      3-7, 9-14, 16-20, 23 June 		<br />
                      13-14, 24-25, 30 July  	<br />
                      13, August  	<br />
                      3-6, 9-13, 16-20, 22-23, 25-27, 29-30 September 	<br />
                      3-4, 7, 9-10, October <br />
                      7, November <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1-6, 13, 20, April <br />
                      5, 11, 18, 24-31 May<br />
                      1-2, 8, 15, 21-22, 24-30 June  <br />
                      1-12, 19-23, 26-29, 31 July 	 <br />
                      1-12, 14-31 August 	 <br />
                      1-2, 7-8, 14-15, 21, 28 September <br />
                      6, 11, 14-18, 21-25 October  <br />
                      3, 8, November <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super Peak Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      15-18, July  <br />
                      5, 12-13, 19-20, 26-31 October  <br />
                      1-2, 9-10, November  <br />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th colspan='6'>Chessington world of adventures resort</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      23, 30 April <br />
                      7, 14, May  <br />
                      17, 24, September  <br />
                      1-2, 8-9, 15-16, October <br />
                      4-8, 11-15, 18-22, 25-29 November <br />
                      2-6, 9-12, 25-26 December   <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      15-19, 22, 24-26, 29 April  <br />
                      1-3, 8-10, 13, 15-17, 20-23, May  <br />
                      4-6, June  <br />
                      3-6, 9-13, 16, 18-20, 23, 25-27, 30 September   <br />
                      3-4, 7, 10-11, 14, 17-18 October  <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      21, 28 April  <br />
                      24, May  <br />
                      3, 7, 10-14, 17-21, 24-28 June <br />
                      1-5, 8-12, 15-17, July <br />
                      21-25, October  <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1-14, 20, 27 April<br />
                      4-6, 11-12, 18-19, 25-31 May 		<br />
                      1-2, 8-9, 15-16, 22-23, 29-30 June <br />
                      6-7, 13-14, 18-31 July 	<br />
                      1-16, 18, August 		<br />
                      1-2, 7-8, 14-15, 21-22, 28-29 September 	<br />
                      5-6, 12-13, 19-20, 26-31 October 	<br />
                      1-3 November <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      17, 19-31 August <br />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th colspan='6'>Thorpe Park</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed Dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      14-16, October 	<br />
                      4-30 November 		<br />
                      1-28 December 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-19, 22-26, 29-30 April 		<br />
                      1-3, 7-10, 13-17, 20-24 May 			<br />
                      4-7, 10-12, June 		<br />
                      10-11, 16-18, 23-26, 30 September 				<br />
                      1-2, 7-9, October 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1-5, 7-12, 14-15, 20-21, 28 April 	<br />
                      4-6, 11-12, 18-19, 26-27 May 		<br />
                      2-3, 9, 13-14, 16, 23, 30 June 	<br />
                      7, 14, 21, 28, July 		<br />
                      4, August 	<br />
                      2-6, 9, 12-13, 15, 19-20, 22, 27, 29 September 	<br />
                      3, 10 October 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak date</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      6, 13, 27, April 		<br />
                      25, 28-31 May 		<br />
                      1, 8, 15, 17-22, 24-29 June 			<br />
                      1-6, 8-13, 15-20, 22-27, 29-31 July 				<br />
                      1-3, 5-31 August 			<br />
                      1, 7-8, 14, 21, 28 September  		<br />
                      4-6, 11-13, 17-18, 20-24, October 			<br />
                      1-3 November<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      19, 25-31 October<br />
                    </td>
                  </tr>

                </table>
                <table>
                  <tr>
                    <th colspan='6'>The Legoland&#174; windsor resort</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      17, 24, April 	<br />
                      8, 15, May 	<br />
                      25-26, September 	<br />
                      2, 9, October		<br />
                      4-8, 11-22, 25-29 November 		<br />
                      2-6, 9-12, 24-26 December 	<br />                     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16, 18, 22-23, 25, 29-30 April <br /> 
                      3, 7, 9, 14, 21-22 May 	<br />
                      9-14, 16-21, June 		<br />
                      4, 9-12, 16-19, 23-24, 30 September 	<br />
                      1, 3, 7-8, 10, 14-17, October <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      15, 19, 21, 26, 28, April 	<br />
                      3, 5-6, 10, 12-13, 16-17, 20, 23 May 			<br />
                      2, 4-7, 23-28 June 			<br />
                      1-4, 9-11, 16-18, 22, July 			<br />
                      2-3, 5-6, 8, 13, 15, 20, 22, 27, 29 September 		<br />
                      4, 11, 18, 20-24 October 	<br />
                      3, November 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1-14, 20, 27 April 		<br />
                      4, 11, 18-19, 24-31 May 		<br />
                      1, 3, 8, 15, 22, 29-30 June 		 		<br />
                      5-8, 12-15, 19-21, 23-31 July 			<br />
                      1-17, 19-20, 26-27 August 			<br />
                      1, 7, 14, 21, 28 September 			<br />
                      5-6, 12-13, 19, 25, 27-31 October 		<br />
                      1-2, November  		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      18, 21-25, 28-31 August <br />
                      26, October <br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Warwick castle resort</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25, December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 		<br />
                      1, 14-15, 21-22, May <br />
                      10-11, 17, September  <br />
                      4-8, 11-15, 18-22, November 	<br />
                      17-18, December 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    8-12, 15, 18-19, 22-26, 29 April 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May 			<br />
                    3-4, 10-11, 17-18, 24-25, June 		<br />
                    1-2, 8-9, 15-16, July  		<br />
                    2-6, 9, 12-13, 16, 18-20, 23-27, 30 September 		<br />
                    1-4, 7-11, 14-18, 21-25, October 		<br />
                    9-10, 16-17, 23-29, November 		<br />
                    2-6, 9-13, 16, 19-20, December 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      2-7, 13-14, 20-21, 27-28, April 	<br />
                      11-12, 18-19, 28-31 May 			<br />
                      5-7, 12-14, 19-21, 26-28 June 		<br />
                      3-5, 10-12, 17-19, 21, 29 July 			<br />
                      6, 13, 20, 27, August 		<br />
                      7-8, 14-15, 21-22, 28-29, September 		<br />
                      5-6, 12-13, 19-20, 27-30 October 	 	<br />
                      1, 3, 30 November 	 	<br />
                      1, 7-8, 14-15, 21-24, 26-31 December 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1, April		<br />
                      4-6, 25-27 May 			<br />
                      1-2, 8-9, 15-16, 22-23, 29-30 June 		<br />
                      6-7, 13-14, 20-22, 24-28, 30-31 July 		<br />
                      1-5, 7-12, 14-19, 21-26, 28-31 August 				<br />
                      1, September 		<br />
                      26, 31 October 		<br />
                      2, November <br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>London Eye</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17 April 		<br />
                      1, 14-15, 21-22 May <br />
                      10-11, 17-18, 24-25 September  <br />
                      5-6, 12-13, 19-20, 26-27 November 		<br />
                      3-5, 10-11, 17-18 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26 April 	 	<br />
                    2-3, 6-10, 13, 16-17, 20, 23-24 May		 	 	<br />
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 		 	<br />
                    1-4, 7-11, 14-18, 21-25 October 					 	<br />
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29 November 	 	<br />
                    2, 6, 9, 12-13, 16, 19-20 December  	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    3-7, 10-14, 17-21, 24-28, June <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      1-14, 20-21, 27-30 April 			<br />
                      4-5, 11-12, 18-19, 25-31 May 			<br />
                      1-2, 8-9, 15-16, 22-23, 29-30 June			<br />
                      1-31, August 	 		<br />
                      1, 7-8, 14-15, 21-22, 28-29 September 			<br />
                      5-6, 12-13, 19-20, 26-31 October 			<br />
                      1-3, 9-10, 16-17, 23-24, 30, November 		<br />
                      1, 7-8, 14-15, 21-24, 26-31 December 	<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>London Dungeon</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 		<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      15, 18-19, 22-26, 29, April 		 	<br />
                      2-3, 6-10, 13, 16-17, 20, 23-24 May 			 	<br />
                      3-7, 10-14, 17-21, 24-28, June 	<br />
                      9, 12-13, 16, 19-20, 23, 26-27, 30 September 	 	<br />
                      1-4, 7-11, 14-18, 21-25, October		 	<br />
                      4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29, November 			 	<br />
                      2, 5-6, 9, 12-13, 16, 19-20, December 		 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      14, 20-21, 27-28, April 	 <br />
                      4-5, 11-12, 18-19, 25 May 			 <br />
                      2, 8-9, 15-16, 22-23, 29-30 June 	 <br />
                      1-20, July 		 <br />
                      2-6, 8, 14-15, 21-22, 28-29 September 		 <br />
                      5-6, 12-13, 19-20, 26 October 		 <br />
                      9-10, 16-17, 23-24, 30 November 	 <br />
                      1, 7-8, 14-15, 21 December  	 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, April 		<br />
                    26-31, May		<br />
                    1, June 			<br />
                    21-31, July 	 		<br />
                    1-31, August 			<br />
                    1, 7, September 			<br />
                    27-31, October 			<br />
                    1-3, November 	 	<br />
                    22-24, 26-31 December	 	<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Edinburgh Dungeon</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 		<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14-15, 18-19, 21-26, 28-29, April 			 	<br />
                    2-3, 6-10, 12-13, 16-17, 19-20, 23-24, May 		 	<br />
                    2-7,9-14, 16-21, 23-28 June 		 	<br />
                    1-6, 8-9, 12-13, 15-16, 19-20, 22-23, 26-27, 29-30 September 		 	<br />
                    1-4, 6-11, 20-25, 29-31 October 			 	<br />
                    1, 3-4, 7-8, 10-11, 14-15, 17-18, 21-22, 24-25, 28-29 November 				 	<br />
                    2, 5-6, 9, 12-13, 15-16, 19-20, 22-24, 26-27 December 		 	<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      14, 20-21, 27-28, April 	 <br />
                      4-5, 11-12, 18-19, 25 May 			 <br />
                      1, 8, 15, 22 June 	 <br />
                      14-31, August 			 <br />
                      7, 14, 21, 28 September 			 <br />
                      5, 12, October 			 <br />
                      2, 9, 16, 23, 30 November 	 	 <br />
                      1, 7-8, 14, 21, 28-31 December  	 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, April 			<br />
                    5, May 			<br />
                    29-30, June 					<br />
                    1-31, July 		 	 		<br />
                    1-13, August 	 	<br />
                    13-19, 26-28, October 			<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>York Dungeon</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 		<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    7-12, 14-15, 18-19, 21-26, 28-29 April				 	<br />
                    2-3, 6-10, 12-13, 16-17, 19-20, 23-24 May 				 	<br />
                    2-7, 9-14, 16-21, 23-28, 30 June 				 	<br />
                    1-5, 7-12, 14-19 July				 	<br />
                    1-6, 8-9, 12-13, 15-16, 19-20, 22-23, 26-27, 29-30 September 				 	<br />
                    1-4, 6-11, 1518, 20-25 October 				 	<br />
                    3-4, 7-8, 10-11, 14-15, 17-18, 21-22, 24-25, 28-29 November 				 	<br />
                    1-2, 5-6, 8-9, 12-13, 15-16, 19-20, 22-24, 26, 28-31 December 			 	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    4, 11, 18, 25-27, 31 May			 <br />
                    1, 8, 15, 22 June 				 <br />
                    21-26, 28 July 			 <br />
                    4, 11, 18, 25 August 					 <br />
                    21, December			 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-6, 13, 20, 27 April 	 			<br />
                    5, 28-30 May 				<br />
                    29, June 	 					<br />
                    6, 13, 20, 27, 29-31 July 		 	 		<br />
                    1-3, 5-10, 12-17, 19-24, 26-31, August 		 	 	<br />
                    7, 14, 21, 28, September				<br />
                    5, 12-14, 19, 26-31 October 					<br />
                    1-2, 9, 16, 23, 30 November					<br />
                    7, 14, 27 December 			<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Shrek's Adventure London</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 		<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14-15, 18-19, 21-26, 28-29 April 		 	<br />
                    2-3, 5-10, 12-13, 16-17, 19-20, 23-24, May 					 	<br />
                    2-7, 9-14, 16-21, 23-28, 30 June 			 	<br />
                    1-5, 7-12, 14-19, July 			 	<br />
                    8-9, 12-13, 15-16, 19-20, 22-23, 26-27, 29-30 September 				 	<br />
                    1-4, 6-11, 13-18, 20-25 October					 	<br />
                    3-4, 7-8, 10-11, 14-15, 17-18, 21-22, 24-25, 28-29 November 					 	<br />
                    1-2, 5-6, 8-9, 12-13, 15-16, 19-20, 27-31 December 	 	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    20, 27 April 		 <br />
                    4, 11, 18, 25 May 		<br/>
                    8, 15, 22, 29 June 		 <br />
                    1, 8, 15, 22 June 				 <br />
                    21-26, 28 July 			 <br />
                    4, 11, 18, 25 August 					 <br />
                    21, December			 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, April 		 			<br />
                    26-31, May 					<br />
                    1, June 		 	 					<br />
                    26-27, July 	 		 	 		<br />
                    2-31, August 	 		 	 	<br />
                    1, 7, September 					<br />
                    26-31, October 	 					<br />
                    1-2, November 					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Blackpool Tower Eye & Blackpool Dungeon</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    4, 7-11, 14-18, 21-25, 28-30 November 		 	<br />
                    1-2, 5-9, 12-16, 19-24, 26-31 December 				 	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29, April		 <br />
                    2-3, 6-10, 13, 16-17, 20, 23-24, May 				<br/>
                    3-7, 10-14, 17-21, 24-28, June 				 <br />
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 					 <br />
                    1-4, 7-11, 14-18, October 			 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-14, 20-21, 27-28 April 	 		 			<br />
                    4-5, 11-12, 18-19, 25-31, May 						<br />
                    1-2, 8-9,15-16, 22-23, 29-30 June 		 	 					<br />
                    1-31, July 	 		 	 		<br />
                    1-31 August 	 	 		 	 	<br />
                    1, 7-8, 14-15, 21-22, 28-29, September 						<br />
                    5-6, 12-13, 19-31, October 		 					<br />
                      1-3, November  					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Cadbury World</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    23 June <br />
                    8 September <br />
                    25 - 26 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22 May 		<br />
                      10-11, 17-18, 24-25 September 			<br />
                      5-6, 12-13, 19-20, 26-27 November 	<br />
                      3-4, 10-11, 17-18 December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    8-12, 15, 18-19, 22-26, 29 April 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May 	<br />	
                    2-7, 10-14, 17-21, 24-28, June 	<br />
                    1-5, July 		<br />
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 	<br />
                    1-4, 7-11, 14-18, October 	<br />
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29, November <br />	
                    2, 5-6, 9, 12-13, 16, December 				<br />	
                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    7, 13-14, 20-21, April 		<br />
                    4-5, 11-12, 18-19, 25, 28, 30-31 May <br />		
                    1, 8-9. 15-16, 22, 29-30 June 		<br />
                    6-12, 14-19, 29 July 		<br />
                    2, August 	<br />
                    1, 7, 14-15, 21-22, 28-29, September 	<br />
                    5-6, 13, 20-25 October 	<br />
                    3, 9-10, 17, 24, November <br />

                    19-20,  December<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-6, 27-28 April 		<br />
                    6, 26-27, 29 May 		<br />
                    13, 20-28, 30-31 July 	<br />	
                    1, 3-31 August 		<br />
                    12, 19, 26-31 October 	<br />
                    1-2, 16, 23, 30 November 	<br />
                    1, 7-8, 14-15, 21-24, 27-31 December<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Legoland Discovery Centre Birmingham</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    8-12, 15, 18-19, 22-26, 29 April			 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May  					 	<br /> 
                    3-7, 10-14, 17-21, 24-28, June 					 	<br /> 
                    1-5, 8-12, 15-19, July 								 	<br /> 
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 	 	 					 	<br /> 
                    1-4, 7-11, 14-18, 21-25, October 		 	<br /> 
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29, November 					 	<br /> 
                    1-2, 5-6, 8-9, 12-13, 15-16, 19-20, 22-24, 26 December 		 	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    7, 14, 21, 28 April 			 <br />
                    5, 12, 19, May								<br/>
                    2, 9, 16, 23, 30 June 	 		 				 <br />
                    7, 14, July 	 						 <br />
                    1, 8, 15, 22, 29 September 				 <br />
                    6, 13, 20, October 						 <br />
                    3, 10, 17, 24, November  	 				 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-6, 13, 20, 27 April 				 			<br />
                    4, 6, 11, 18, 25-31 May 							<br />
                    1, 8, 15, 22, 29 June 					 	 					<br />
                    6, 13, 20-31 July 		 			 		 	 		<br />
                    1-31 August 		 	 	 		 	 	<br />
                    7, 14, 21, 28 September 	 						<br />
                    5, 12, 19, 26-31, October 			 					<br />
                    1-2, 9, 16, 23, 30 November 	 			 					<br />
                    7, 14, 21, 27-31 December  			 					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Legoland Discovery Centre Manchester</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29 April				 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May		  					 	<br /> 
                    3-7, 10-14, 17-21, 24-28 June 							 	<br /> 
                    1-5, 8-12, 15-19 July 								 	<br /> 
                    3-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 		 					 	<br /> 
                    1-4, 7-11, 14-18, 28-31 October 			 	<br /> 
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29 November 	 					 	<br /> 
                    2, 5-6, 9, 12-13, 15-16, 19-20, 22-24 December 		 	<br />                   
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14, 21, 28 April					 <br />
                    4, 11-12, 18-19, 25-31 May 								<br/>
                    1-2, 8-9, 15-16, 22-23, 30 June 		 		 				 <br />
                    7, July  	 						 <br />
                    1-2, 8, 15, 22, 29 September	 				 <br />
                    10, 17, November 							 <br />
                    1- 7-8, 14, 21 December  	 				 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, 20, 27, April 		 				 			<br />
                    5-6, May 							<br />
                    29, June 					 	 					<br />
                    6, 13-14, 20-31, July 		 			 		 	 		<br />
                    1-31, August 	 		 	 	 		 	 	<br />
                    7, 14, 21, 28, September 		 						<br />
                    5-6, 12-13, 19-27, October 				 					<br />
                    2-3, 9, 16, 23-24, 30 November 	 					<br />
                    26-31 December 	 					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Sealife London</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18, 22-25, 29 April 				 	<br />
                    2, 6-9, 13, 16, 20, 23 May 		  					 	<br /> 
                    9, 12, 16, 19, 23, 26, 30 September 								 	<br /> 
                    1-3, 7-10, 14-17, 21-24, October 								 	<br /> 
                    4, 7, 11, 14, 18, 21, 25, 28 November 	 					 	<br /> 
                    2, 5, 9, 12, 16, 19, December 			 	<br />              
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    19, 26, April				 <br />
                    3, 10, 17, 24 May 									<br/>
                    3-7, 10-14, 17-21, 24-28, June 		 		 				 <br />
                    1-5, 8-12, 15-19, July	 	 						 <br />
                    2-6, 13, 20, 27 September 			 <br />
                    4, 11, 18, 25, October 								 <br />
                    8, 15, 22, 29 November 		 				 <br />
                    6, 13, 20, 27-31 December 	 				 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-14, 20-21, 27-28 April 		 				 			<br />
                    4-5, 11-12, 18-19, 25-31 May 							<br />
                    1-2, 8-9, 15-16, 22-23, 29-30 June 						 	 					<br />
                    6-7, 13-14, 20-31 July 			 		 	 		<br />
                    1-31 August 		 		 	 	 		 	 	<br />
                    1, 7-8, 14-15, 21-22, 28-29 September 		 						<br />
                    5-6, 12-13, 19-20, 26-31 October 	 					<br />
                    1-3, 9-10, 16-17,23-24, 30 November 	 	 					<br />
                    1, 7-8, 14-15, 21-24, 26 December 	 					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Madame Tussauds London</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    2, 8, 13, 20, 23-24 May 				 	<br />
                    4, 17, 19 June				  					 	<br /> 
                    2-6, 8-9, 12-13, 16, 19-20, 23, 26,30 September 									 	<br /> 
                    1-4, 7-10, 15-16 October 									 	<br /> 
                    3-4, 7-8, 11, 14, 18, 21-22, 25, 28-29 November 	 	 					 	<br /> 
                    2, 5, 9, 12, 16,19-20, 22-24, December 		 	<br />              
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14-15, 18-19, 23-24, 26, 28-29 April 					 <br />
                    3, 9-12, 19, May 										<br/>
                    2-3, 5-7, 9-16, 18, 20-21 June 					 <br />
                    1, 7, 14-15, 21-22, 27 September 	 	 						 <br />
                    5-6, 11, 14, 17-18, October 				 <br />
                    9-10, 15, November 						 <br />
                    1, 6, 8, 13, 21 December  		 				 <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, 20-22, 25, 27 April	 		 				 			<br />
                    4-7, 16-18, 25-31 May 								<br />
                    1, 8, 22-30 June 							 	 					<br />
                    1-31 July 			 		 	 		<br />
                    1-31 August 		 		 	 	 		 	 	<br />
                    28-29 September 	 		 						<br />
                    12-13, 19-31 October 						<br />
                    1-2, 16-17, 23-24, 30 November 	 	 	 					<br />
                    7, 14-15, 26-31 December  					<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Sealife Brighton</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29 April 		 				 	<br />
                    2-3, 7-11, 13, 16-17, 20, 23-24 May						  					 	<br /> 
                    3-7, 10-14, 17-21, 24, 27-28 June 									 	<br /> 
                    1, 8, 11-12,5, 18-19 July 		 									 	<br /> 
                    3-9, 12-16, 19-23, 26-30 September 	 	 					 	<br /> 
                    1-4, 6-11, 13-18, 20-25 October 	 		 	<br />     
                    3-4, 7-11, 14-18, 21-25, 28-30 November 	 	 		 	<br />              
                    1-2, 5-9, 12-16, 19-24, 26-31 December  		 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14, 21, 28 April 		 				 			<br />
                    12, 18-19, 25-27 May 									<br />
                    1-2, 8-9, 15-16, 22-23, 25-26, 29-30 June  							 	 					<br />
                    2-5, 9-10, 16-17 July  			 		 	 		<br />
                    3-4, 11, 18, 25 August 	 	 		 						<br />
                    5, 12,19, 26-31 October 							<br />
                    1-2, November  	 	 	 					<br /> 
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, 20, 27 April 		 		 				 			<br />
                    4-6, 28-31 May 									<br />
                    6-7, 13-14, 20-31 July 								 	 					<br />
                    1-2, 5-10, 12-17, 19-24, 26-31 August 	  			 		 	 		<br />
                    1-2 September  	 	 		 						<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Sealife Great Yarmouth</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14, 18-19, 21, 23-26, April 			 				 	<br />
                    2-3, 5, 7-10, 12, 16-17, 23-24 May 					  					 	<br /> 
                    2-24, 27-28, 30 June 	 									 	<br /> 
                    1, 4-8, 11-12, 14-15, 19 July 		 		 									 	<br /> 
                    3-9, 12-16, 19-23, 26-30 September 						 	<br /> 
                    1-26, October 		 		 	<br />     
                    3-4, 7-11, 14-18, 21-25, 28-30 November 		 	 		 	<br />              
                    1-2, 5-9, 12-16, 19-24, 26-31 December  		 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1, 8, 13, 15, 20, 22, 27, 29 April			 				 			<br />
                    4, 6, 11, 13, 18-20, 25 May 											<br />
                    25-26, 29, June 									 	 					<br />
                    2-3, 9-10, 16-18 July 			 		 	 		<br />
                    27-31, October 		 	 		 						<br />
                    1-2, November  							<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    2-7, 9-12, 28 April 			 		 				 			<br />
                    26-31 May 								<br />
                    1 July 								 	 					<br />
                    1-31 August 		  			 		 	 		<br />
                    1-2 September  	 	 		 						<br />
                    </td>
                  </tr>
                  </table>

                  <table>
                  <tr>
                    <th colspan='6'>Sealife Hunstanton</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14-15, 18-29, April 			 				 	<br />
                    2-4, 7-13, 16-20, 23-24 May 				  					 	<br /> 
                    2-7, 9-14, 16-21, 23-28, 30 June 								 	<br /> 
                    1, 4-5, 7-8, 11-12, 14-15, 18-19, July 	 									 	<br /> 
                    2-9, 12-16, 19-23, 26-30 September  						 	<br /> 
                    1-26, October 			 		 	<br />     
                    3-4, 7-11, 14-18, 21-25, 28-30 November 			 	 		 	<br />              
                    1-2, 5-9, 12-16, 19-24, 26-31 December 		 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, April 				 				 			<br />
                    6, 25 May										<br />
                    8, 15, 22, 29 June 											 	 					<br />
                    2-3, 6, 9-10, 16-17, July 			 		 	 		<br />
                    27-31, October		 	 		 						<br />
                    1-2, November 					<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    5, 26-31, May 				 		 				 			<br />
                    1, June 									<br />
                    13, 20-31 July 									 	 					<br />
                    1-31 August 			  			 		 	 		<br />
                    1, September   	 	 		 						<br />
                    </td>
                  </tr>
                  </table>

                  <table>
                  <tr>
                    <th colspan='6'>Sealife Weymouth</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14-15, 18-29, April 			 				 	<br />
                    2-13, 16-20, 23-25 May 					  					 	<br /> 
                    2-30 June	 								 	<br /> 
                    1, 4-8, 11-15, 18-19, July 		 									 	<br /> 
                    2-9, 12-16, 19-23, 26-30 September 	  						 	<br /> 
                    1-28, October 				 		 	<br />     
                    1-4, 7-11, 14-18, 21-25, 28-30 November 			 	 		 	<br />              
                    1-2, 5-9, 12-16, 19-24, 26-31 December 	 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, April 				 				 			<br />
                    26-27, 31 May 										<br />
                    1, June 													 	 					<br />
                    2-3, 9-10, 16-17, July 			 		 	 		<br />
                    29-31, October 		 	 		 						<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    28-30, May 	 				 		 				 			<br />
                    20-31, July 									<br />
                    1-31, August 								 	 					<br />
                    1, September 	  			 		 	 		<br />
                    </td>
                  </tr>
                  </table>
                  <table>
                  <tr>
                    <th colspan='6'>Sealife Scarborough</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    7-12, 14-15, 18-19, 21-26, 29 April				 				 	<br />
                    2-3, 7-10, 12-13, 16-17, 19-20, 23-24, May 	 					  					 	<br /> 
                    2-7, 9-14, 16-17, 19-21, 23-24, 27-28, 30 June 										 	<br /> 
                    1, 4-5, 7-8, 11-12, 15, 19 July 											 	<br /> 
                    3-9, 12-16, 19-23, 26-30 September 						 	<br /> 
                    1-26, October					 		 	<br />     
                    3-4, 7-11, 14-18, 21-25, 28-30 November 			 	 		 	<br />              
                    1-2, 5-9, 12-16, 19-24, 26-31 December  	 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    6, 13, 20, 27 April 		 				 			<br />
                    4, 6, 11, 18, 25, 31 May 											<br />
                    1, 8, 15, 18, 22, 25-26, 29 June 														 	 					<br />
                    2-3, 9-10, 14, 16-18, July 		 		 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-5, 28 April 	 	 				 		 				 			<br />
                    5, 26-30 May		 									<br />
                    6, 13, 20-31 July 		 								 	 					<br />
                    1-31 August 	  			 		 	 		<br />
                    1-2, September 	  			 		 	 		<br />
                    27-31, October 				 		 	 		<br />
                    1-2, November   			 		 	 		<br />
                    </td>
                  </tr>
                  </table>

                  <table>
                  <tr>
                    <th colspan='6'>Sealife Loch Lomond</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29 April 			 				 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24, 28-31 May					  					 	<br /> 
                    2-7, 9-14 June 									 	<br /> 
                    16-21, 24, 27-28 June 												 	<br /> 
                    21-23, 26, 28-30 August 								 	<br /> 
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27 September 				 		 	<br />     
                    1-4, 6-11, 13-31, October			 	 		 	<br />              
                    1, 3-4, 7-11, 14-18, 21-25, 28-30 November 	 	<br />    
                    1-2, 5-9, 12-16, 19-24, 26-31 December 	 	<br />     
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    14, 21,28, April 		 				 			<br />
                    11-12, 18-19, 25-27 May 		 											<br />
                    1, 8, 15, 22-23, 25-26 June 															 	 					<br />
                    25-27 August 			 		 	 		<br />
                    1, 7-8, 14-15, 21-22, 28-30 September 				 		 	 		<br />
                    5, 12, October 				 		 	 		<br />
                    2, November		 		 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-13, 20, 27 April 			 	 				 		 				 			<br />
                    4-6, May 		 									<br />
                    29-30, June 		 								 	 					<br />
                    1-31 July 	  			 		 	 		<br />
                    1-20, 24, 31 August 		  			 		 	 		<br />
                    </td>
                  </tr>
                  </table>

                  
                  <table>
                  <tr>
                    <th colspan='6'>Sealife Manchester</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29 April 			 				 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May 							  					 	<br /> 
                    2-7, 9-14, 17-21, 23-28, June 							 	<br /> 
                    1, 4-5, 8, 11-12, 15, 18-19 July 											 	<br /> 
                    3-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 									 	<br /> 
                    1-4, 7-11, 14-18, 28-31 October 					 		 	<br />     
                    1, 4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29, November	 	 		 	<br />              
                    2, 5-6, 8-9, 12-13, 15-16, 19-20, 22-24, 27-31 December 	 	<br />        
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    28, April 			 				 			<br />
                    11-12, 18-19, 25-31 May				 											<br />
                    1, 8, 15 June 												 	 					<br />
                    2-3, 9-10, 16-17 July 				 		 	 		<br />
                    1, 7-8, 14-15, 21, 29 September 				 		 	 		<br />
                    6, 13, 27 October 				 		 	 		<br />
                    3, 10, 17, 24 November 	 		 	 		<br />
                    1, 7, 14, 21 December 	 		 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-14, 20-21, 27 April 				 	 				 		 				 			<br />
                    4-6, May 		 									<br />
                    16, 22, 29-30 June 	 								 	 					<br />
                    6-7, 13-14, 20-31 July 	 	  			 		 	 		<br />
                    1-31 August 	  			 		 	 		<br />
                    2, 22, 28 September 				 		 	 		<br />
                    5, 12, 19-26 October 		  			 		 	 		<br />
                    2, 9, 16, 23, 30 November 		  			 		 	 		<br />
                    26, December   			 		 	 		<br />
                    </td>
                  </tr>
                  </table>

                  <table>
                  <tr>
                    <th colspan='6'>Sealife Birmingham</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    8-12, 15, 18-19, 22-26, 29 April 			 				 	<br />
                    2-3, 7-10, 13, 16-17, 20, 23-24 May								  					 	<br /> 
                    3-7, 10-14, 17-21, 24-28 June 							 	<br /> 
                    1, 4-5, 8, 11-12, 15, 18-19, July 		 											 	<br /> 
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 								 	<br /> 
                    1-4, 7-11, 14-18, 21-25, October 						 		 	<br />     
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29 November 		 	 		 	<br />              
                    1-2, 5-6, 9, 12-13, 15-16, 19-20, 22-24, 26, 28-31 December 	 	<br />        
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    12, 19, 26, 31 May				 				 			<br />
                    2, 9, 16, 23, 30 June 			 											<br />
                    2-3, 7, 9-10, 16-17, July 													 	 					<br />
                    1, 8, 15, 22, 29 September 				 		 	 		<br />
                    6, 13, 20, October 					 		 	 		<br />
                    3, 10, 17, 24, November 				 		 	 		<br />
                    27, December 		 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-7, 13-14, 20-21, 27-28, April 		 	 				 		 				 			<br />
                    4-6, 11, 18, 25, 27-30 May 			 									<br />
                    1, 8, 15, 22, 29 June 								 	 					<br />
                    6, 13-14, 20-31 July 			 	  			 		 	 		<br />
                    1-31, August 	 			 		 	 		<br />
                    7, 14, 21, 28 September 		 		 	 		<br />
                    5, 12, 19, 26-31 October 			  			 		 	 		<br />
                    1-2, 9, 16, 23, 30 November 			  			 		 	 		<br />
                    7, 14, 21, December    			 		 	 		<br />
                    </td>
                  </tr>
                  </table>

                  <table>
                  <tr>
                    <th colspan='6'>Sealife Blackpool</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    4, 7-8, 11, 14-15, 18, 21-22, 25, 28-29 November 			 				 	<br />
                    2, 5-6, 9, 12-13, 16, 19-20, 23-24, 26 December 							  					 	<br />        
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29 April					 				 			<br />
                    2-3, 6-10, 13, 16-17, 20, 23-24, May 				 											<br />
                    3-7, 10-14, 17-21, 24-28, June 										 	 					<br />
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 			 		 	 		<br />
                    1-4, 7-11, 14-18, October 					 		 	 		<br />
                    9-10, 16-17, 23-24, 30 November 					 		 	 		<br />
                    1, 7-8, 14-15, 21-22, December 	 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-14, 20-21, 27-28 April 	 	 				 		 				 			<br />
                    4-5, 11-12, 18-19, 25-31 May 		 									<br />
                    1-2, 8-9, 15-16, 22-23, 29-30 June 								 	 					<br />
                    1-31 July 			 	  			 		 	 		<br />
                    1-31 August 	 			 		 	 		<br />
                    1, 7-8, 14-15, 21-22, 28-29, September 	 		 	 		<br />
                    5-6, 12-13, 19-31 October 	  			 		 	 		<br />
                    1-3, November 			  			 		 	 		<br />
                    27-31, December    			 		 	 		<br />
                    </td>
                  </tr>
                  </table>

                <table>
                  <tr>
                    <th colspan='6'>Blackpool Tower Circus</th>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Closed</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                      25 December <br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    16-17, 30 April 				<br />
                      1, 14-15, 21-22, May 		<br />
                      10-11, 17-18, 24-25, September 			<br />
                      5-6, 12-13, 19-20, 26-27, November 	<br />
                      3-4, 10-11, 17-18, December 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    4, 7-11, 14-18, 21-25, 28-30 November<br />
                    1-2, 5-9, 12-16, 19-24, 26-27, 30-31 December	<br />        
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    15, 18-19, 22-26, 29, April 					 				 			<br />
                    2-3, 6-10, 13, 16-17, 20, 23-24, May 	<br />
                    3-7, 10-14, 17-21, 24-28, June 							 	 					<br />
                    2-6, 9, 12-13, 16, 19-20, 23, 26-27, 30 September 				 		 	 		<br />
                    1-4, 7-11, 14-18, October 						 		 	 		<br />
                    28-29 December 				 		 	 		<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan='6' className='tandc__heading'>Super peak dates</td>
                  </tr>
                  <tr>
                    <td colspan='6'>
                    1-14, 20-21, 27-28 April 	 				 		 				 			<br />
                    4-5, 11-12, 18-19, 25-31 May			 									<br />
                    1-2, 8-9, 15-16, 22-23, 29-30 June 						 	 					<br />
                    1-31 July 			 	  			 		 	 		<br />
                    1-31 August 			 		 	 		<br />
                    1, 7-8, 14-15, 21-22, 28-29, September 	 	 		<br />
                    5-6, 12-13, 19-31 October 				 		 	 		<br />
                    1-3, November <br />
                    </td>
                  </tr>
                  </table>
            </li>
            <li>
              <p>2025 allocations will be updated on these terms and conditions and available from 20th January 2025 once annual calendars are confirmed. The dates listed as OFF PEAK, SUPER OFF PEAK, PEAK and SUPER PEAK may be amended from time to time, in which case these terms and conditions will be updated accordingly.</p>
            </li>
            <li>
              <p>The offer is subject to the attractions' annual opening calendars. All dates are subject to change and may differ from those listed in Table B. Please check all opening dates and times before your visit on the attraction's individual websites.</p>
              <ul>
                <li><p><strong>The Blackpool Tower Eye</strong> will be closed for maintenance during the winter period. Please check opening times via website <a href="https://theblackpooltower.com/plan/before-you-visit/opening-hours" target="_blank" rel="noopener noreferrer">www.theblackpooltower.com/plan/before-you-visit/opening-hours</a>. Opening is subject to weather conditions. Opening times at all Blackpool attractions may vary during the winter season. </p></li>
                <li><p><strong>The Lastminute.com London Eye</strong> is closed for a limited period in January every year for annual maintenance.</p></li>
              </ul>              
            </li>
            <li>
              <p>The offer and/or unique code (where applicable) can only be used as stipulated. The offer and/or unique code have no cash value and are non-transferable/not for resale. All offers and/or unique codes are automatically void if sold, offered for sale or if any attempt or offer is made to transfer the offer and/or unique code for value.  </p>
            </li>
            <li>
              <p>Offer is only valid on promotional packs of Cadbury Chocolate which feature the UP TO 50% promotion including: Fudge 5 pack, Curly Wurly 5 pack, Cadbury Dairy Milk Freddo 5 pack, Cadbury Dairy Milk Freddo Caramel 5 pack, Cadbury Dairy Milk Little Bar 6 pack, Cadbury Dairy Milk 9 pack, Cadbury Double Decker 9 pack, Cadbury Wispa 9pk, Barny 125G Choc Filling and Barny 125g Milk, Cadbury Small Selection Box and Cadbury Medium Selection Box.</p>
            </li>
            <li>
              <p>This offer is not valid at secondary attractions and events including, but not limited to; Zoo Days or Winters Tail Event at Chessington World of Adventures Resort, The Alton Towers Dungeon, Alton Towers Spa, Extraordinary Golf, Treetop Quest, the Waterpark and the Scarefest Halloween Event Mazes at Alton Towers Resort, Fright Nights Mazes at Thorpe Park, The Castle Dungeon and Dragon Slayer at Warwick Castle, Junior Brick Week or Christmas events at LEGOLAND&#174; Windsor Resort, LATES at The Dungeons and SEA LIFE London Aquarium, Escape Rooms, Cage Snorkel, Shark Dive, The Blackpool Tower Ballroom, Bar 380, The Fifth Floor or Dino Golf at The Blackpool Tower Eye.</p>
            </li>
            <li>
              <p>This offer can only be used for standard single day entrance tickets and cannot be used for any combination, fastrack/priority/early ride time, 2-day or short break tickets including, but not limited to; Merlins Magical London Ticket at London Attractions, Multi-Attraction tickets at The Blackpool Tower Attractions, Merlin Annual Pass, Season Pass. </p>
            </li>
            <li>
              <p>This offer is not valid for accommodation including but not limited to; The Alton Towers Hotel, CBeebies Land Hotel, Splash Landings Hotel, Enchanted Village (Woodland Lodges and Luxury Treehouses) and Stargazing Pods at the Alton Towers Resort, Safari Hotel, Azteca Hotel and Explorer Glamping at Chessington World of Adventures Resort, Tower Suites and Knight's Village at Warwick Castle, Thorpe Shark Cabins at Thorpe Park or the LEGOLAND&#174; Resort Hotel and The LEGOLAND&#174; Castle Hotel at the LEGOLAND&#174; Windsor Resort. Additional costs apply. Please check each attraction's website for additional costs. </p>
            </li>
            <li>
              <p>This offer is not valid for transport or car parking. Some attractions operate a chargeable car parking policy including, but not limited to; Chessington World of Adventures Resort, The LEGOLAND&#174; Windsor Resort, Thorpe Park, Alton Towers Resort and Warwick Castle.</p>
            </li>
            <li>
              <p>The offer and/or unique code which form part of this offer remains the property of Merlin Entertainments at all times and as such Merlin Entertainments reserves the right to take possession of the offer and/or unique code at any time. </p>
            </li>
            <li>
              <p>Size, height, weight, age restrictions and medical warnings apply to certain rides and/or attractions.</p>
            </li>
            <li>
              <p>Visitors under 12 years must be accompanied by a person aged 18 years or over at Thorpe Park, Warwick Castle, Chessington World of Adventures Resort and Alton Towers Resort. Visitors 14 years and under must be accompanied by a person aged 18 years and over at the LEGOLAND&#174; Windsor Resort, SEA LIFE Aquariums and Centres (Manchester, Blackpool, Brighton, Great Yarmouth, Weymouth, Scarborough, Loch Lomond and Hunstanton) Madame Tussauds Blackpool and The Blackpool Tower Eye and Circus. Visitors 15 years and under must be accompanied by an adult over the age of 18 at SEA LIFE Birmingham, The Dungeons (Edinburgh, York and Blackpool). Visitors 16 years and under must be accompanied by an adult aged 18 and over at The London Eye, Shrek's Adventure! London, SEA LIFE London, Madame Tussauds London and The London Dungeon. No person aged 18 and over can enter the LEGOLAND&#174; Discovery Centres (Manchester or Birmingham) without an accompanying child/ children aged 17 years or younger.</p>
            </li>
            <li>
              <p>For the purpose of admissions an adult is classified as 12 years of age and over at the Alton Towers Resort and Chessington World of Adventures Resort. An adult is classified as a person 15 years of age and over at SEA LIFE Aquariums and Centres (Manchester, Blackpool, Brighton, Great Yarmouth, Weymouth, Scarborough, Loch Lomond and Hunstanton), Madame Tussauds Blackpool, The Blackpool Tower Dungeon, The Blackpool Tower Eye and The Blackpool Tower Circus. An adult is classified as a person 16 years of age and over at The London Eye, Shrek's Adventure! London, Madame Tussauds London, SEA LIFE London Aquarium, The Dungeons (London, York and Edinburgh). An adult is classified as a person 18 years of age and over at LEGOLAND&#174; Discovery Centres (Manchester or Birmingham). Some attractions operate an individual ticket price which is the same for both adults and children for the purpose of admissions. This includes but is not limited to; The LEGOLAND&#174; Windsor Resort, Warwick Castle, Thorpe Park, LEGOLAND&#174; Discovery Centre Birmingham and SEA LIFE Birmingham.</p>
            </li>
            <li>
              <p>Under 2's go free at most Merlin Attractions apart from the below attractions, where free entry is based on height- for full details please check the attraction websites. </p>
              <table>
                  <tr>
                    <th>Attraction</th>
                    <th>Restriction</th>
                  </tr>
                  <tr>
                    <td>LEGOLAND&#174; Windsor</td>
                    <td>Under 90cm</td>
                  </tr>
                  <tr>
                    <td>Thorpe Park</td>
                    <td>Under 1.2m</td>
                  </tr>
                  <tr>
                    <td>Alton Towers</td>
                    <td>Under 1.2m</td>
                  </tr>
                  <tr>
                    <td>Chessington World of Adventures</td>
                    <td>Under 90cm</td>
                  </tr>
                </table>
            </li>
            <li>
              <p>All of the attractions reserve the right, in their absolute discretion, to refuse entry and to close and/or alter all or any part of the facilities including closure of rides and/or attractions and removal of figures for technical, operational, health and safety or other reasons including overcapacity. </p>
            </li>
            <li>
              <p>Features, rides, attractions and/or creatures shown on the website or communications are not necessarily available at every attraction. Please check ahead of your visit to avoid disappointment. </p>
            </li>
            <li>
              <p>All attraction information is subject to change and entry into any participating attraction is subject to the individual attraction's terms and conditions or entry. Please check the attraction website(s) below before your visit for the latest information;</p>
              <ul>
                <li><a href="https://altontowers.com" target="_blank" rel="noopener noreferrer">www.altontowers.com</a></li>
                <li><a href="https://THORPEPARK.com" target="_blank" rel="noopener noreferrer">www.THORPEPARK.com</a></li>
                <li><a href="https://chessington.com" target="_blank" rel="noopener noreferrer">www.chessington.com</a></li>
                <li><a href="https://LEGOLAND.co.uk" target="_blank" rel="noopener noreferrer">www.LEGOLAND.co.uk</a></li>
                <li><a href="https://LEGOLANDDiscoveryCentre.co.uk" target="_blank" rel="noopener noreferrer">www.LEGOLANDDiscoveryCentre.co.uk</a></li>
                <li><a href="https://visitsealife.com" target="_blank" rel="noopener noreferrer">www.visitsealife.com</a></li>
                <li><a href="https://thedungeons.com" target="_blank" rel="noopener noreferrer">www.thedungeons.com</a></li>
                <li><a href="https://madametussauds.com" target="_blank" rel="noopener noreferrer">www.madametussauds.com</a></li>
                <li><a href="https://theblackpooltower.com" target="_blank" rel="noopener noreferrer">www.theblackpooltower.com</a></li>
                <li><a href="https://sealifeweymouth.com" target="_blank" rel="noopener noreferrer">www.sealifeweymouth.com</a></li>
                <li><a href="https://shreksadventure.com" target="_blank" rel="noopener noreferrer">www.shreksadventure.com</a></li>
                <li><a href="https://warwick-castle.com" target="_blank" rel="noopener noreferrer">www.warwick-castle.com</a></li>
                <li><a href="https://cadburyworld.co.uk" target="_blank" rel="noopener noreferrer">www.cadburyworld.co.uk</a></li>
                <li><a href="https://londoneye.com." target="_blank" rel="noopener noreferrer">www.londoneye.com</a></li>
              </ul>
            </li>
            <li>
              <p>In the event of circumstances outside the reasonable control of the Promoter, or otherwise where fraud, abuse, and/or an error (human or computer) affects or could affect the proper operation of this promotion, and only where circumstances make this unavoidable, the Promoter reserves the right to cancel or amend the promotion or these offer terms and conditions, at any stage, but will always endeavour to minimize the effect to participants in order to avoid undue disappointment.</p>
            </li>
            <li>
              <p>If any of these clauses should be determined to be illegal, invalid or otherwise unenforceable then it shall be severed and deleted from these terms and conditions and the remaining clauses shall survive and remain in full force and effect.</p>
            </li>
            <li>
              <p>The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person's negligence) in connection with this promotion or accepting or using the prize/gift, except for any liability which cannot be excluded by law (including personal injury, death and fraud) in which case that liability is limited to the minimum allowable by law.</p>
            </li>
            <li>
              <p>These terms and conditions and any related disputes are subject to interpretation under the laws of England and Wales, and to the jurisdiction of the courts of England and Wales unless your residential address is in Scotland, when Scottish law applies or in the Republic of Ireland, when Irish law applies.</p>
            </li>
          </ol>

          {/*Cadbury Selection Boxes and Seasonal Biscuits 2023*/}
          { isBeforeTargetDate &&

          <>

          <div ref={selection23} className='type-h3 type-h3--gold'>
          TERMS AND CONDITIONS - Cadbury Big Ticket Savings 2023 Selection Boxes And Seasonal Biscuits
          </div>
          <br />
          <p>
            <strong><u>TO REDEEM YOUR OFFER, PRE-BOOKING ONLINE IS ESSENTIAL:</u></strong><br />
            <strong>STEP 1:</strong> Go to <a href='https://fun.cadbury.co.uk/' target='_blank' rel='noopener noreferrer'>www.fun.cadbury.co.uk</a> and enter your barcode and batch code found on the reverse of your promotional pack (full details of where to locate these can be found online), along with your name and email address** and enter required details. </p>
          <p><strong>STEP 2:</strong> Once your pack(s) have been validated you will be emailed your unique code(s) and booking link.   </p>
          <p><strong>STEP 3:</strong> Select your attraction <u>and visit date, according to the discount level preferred (from those available)</u>, enter your unique code(s) when prompted and complete the booking journey and pay for your entry tickets. Each code can only be used once. </p>
          <p><strong>STEP 4:</strong> Print/ download your entry tickets and head straight to the attraction on your booked date of visit, scan your tickets (on your phone or a print-out) under the barcode readers to enter the attraction. </p>
          <p><i>**Please note that only one offer /unique code can be used per person. </i></p>
          <div className="type-h3 type-h3--gold">Full Terms and Conditions:</div>
          <ol>
            <li><p>This offer gives UP TO 50% OFF for up to 4 "On The Day" full priced <strong>Adult and Children entry tickets</strong> at selected Merlin Entertainments Attractions. The discount depends on the attraction and period of time chosen (super off peak, off peak, peak, super peak), from a minimum of 25% in super peak, up to a maximum (for certain attractions) of 55% in super off peak. This offer is valid until 31st December 2024. Merlin Entertainments may limit offer ticket allocations, subject to a minimum number allocated to every day each attraction is open (see full T&Cs, below, for details). Subject to availability at time of booking - book early to secure popular dates e.g. weekends/ school holidays.</p></li>
            <li><p>The offer is based on the full "On The Day" (also referred to as "on the door"/ "gate") priced ticket at each attraction; such prices are subject to change.  </p></li>
            <li><p>Offer valid at the following participating attractions; Alton Towers Resort, Chessington World of Adventures Resort, Thorpe Park Resort, The LEGOLAND® Windsor Resort, Warwick Castle, Shrek's Adventure! London, Madame Tussauds (London or Blackpool), The Dungeons (London, York, Edinburgh or Blackpool Tower), The Blackpool Tower Eye, The Blackpool Tower Eye Circus, The Lastminute.com London Eye, LEGOLAND® Discovery Centres (Manchester or Birmingham), SEA LIFE Aquariums & Centres (Birmingham, Great Yarmouth, Loch Lomond, Blackpool, London, Brighton, Manchester, Scarborough, Hunstanton and Weymouth SEA LIFE Adventure Park). </p></li>
            <li><p>Pre-booking this offer is essential via <a href='https://fun.cadbury.co.uk/' target='_blank' rel='noopener noreferrer'>www.fun.cadbury.co.uk</a>. Follow steps outlined on website, using the barcode and batch code number from the reverse of your pack online to enter. Booking is subject to attraction and ticket allocation availability at the time of booking - book early to secure popular dates, such as weekends, school and bank holidays. </p></li>
            <li><p>A barcode and batch code from a promotional pack can only be used once. Only one offer /unique code can be used per person.</p></li>
            <li><p>This offer is not valid for use in conjunction with any other promotion, offer, reward/loyalty scheme or direct attraction booking. Offer is also not valid for redemption against pre-purchased tickets, group bookings and concession rate tickets including but not limited to; senior citizens, veterans, family tickets, students, merlin annual pass or season pass holders. </p></li>
            <li><p>This offer is valid until <strong>31st December 2024</strong> at all participating attractions.</p></li>
            <li>
              <p>Merlin Entertainments may limit offer ticket allocations, subject to a minimum number allocated to every day each attraction is open. The minimum allocation of tickets during OFF PEAK, SUPER OFF PEAK, SUPER PEAK and PEAK dates (*see table B below for details of these dates) shall be as set out in table A below.  As these are minimum allocations, Merlin Entertainments may (at its sole discretion) from time to time make additional tickets available <u>for purchase at the relevant discount under this offer</u> for particular attractions, so you may wish to check back later if your preferred attraction and date combination are not available when you first attempt to book.</p>
              <p><strong>Table A - Minimum Allocations by Attraction</strong></p>
              <table>
                <tr>
                  <th>Attraction</th>
                  <th>Minimum allocation</th>
                </tr>
                <tr>
                  <td colspan="2" className='tandc__heading'>Super off peak</td>
                </tr>
                <tr>
                  <td>All participating attractions </td>
                  <td>Unlimited availability</td>
                </tr>
                <tr>
                  <td colspan="2" className='tandc__heading'>Off-peak</td>
                </tr>
                <tr>
                  <td>All participating attractions </td>
                  <td>Unlimited availability</td>
                </tr>
                <tr>
                  <td colspan="2" className='tandc__heading'>Peak</td>
                </tr>
                <tr>
                  <td>Alton Towers Resort</td>
                  <td>120 tickets per day </td>
                </tr>
                <tr>
                  <td>Chessington World of Adventures Resort</td>
                  <td>90 tickets per day</td>
                </tr>
                <tr>
                  <td>Thorpe Park Resort</td>
                  <td>70 tickets per day </td>
                </tr>
                <tr>
                  <td>The LEGOLAND® Windsor Resort </td>
                  <td>90 tickets per day </td>
                </tr>
                <tr>
                  <td>Warwick Castle</td>
                  <td>70 tickets per day</td>
                </tr>
                <tr>
                  <td>
                    Shrek's Adventure London,<br />
                    Madame Tussauds London, <br />
                    London Dungeons,<br />
                    SEA LIFE London Aquarium <br />
                    & the lastminute.com <br />
                    London Eye<br />
                  </td>
                  <td>125 tickets per day, per site</td>
                </tr>
                <tr>
                  <td>
                    SEA LIFE Centre Birmingham,<br />
                    Blackpool, Brighton, <br />
                    Great Yarmouth, Hunstanton, <br />
                    Loch Lomond, Manchester, <br />
                    Scarborough and Weymouth <br />
                  </td>
                  <td>75 tickets per day, per site</td>
                </tr>
                <tr>
                  <td>
                    LEGOLAND® Discovery Centre Manchester & Birmingham, <br />
                    Blackpool Dungeons, <br />
                    Blackpool Tower Eye, <br />
                    Blackpool Tower Circus, <br />
                    Madame Tussauds Blackpool, <br />
                    Edinburgh Dungeons, <br />
                    York Dungeons.<br />
                  </td>
                  <td>35 tickets per day, per site</td>
                </tr>
                <tr>
                  <td colspan='2' className='tandc__heading'>Super peak</td>
                </tr>
                <tr>
                  <td>Alton Towers Resort</td>
                  <td>40 tickets per day </td>
                </tr>
                <tr>
                  <td>Chessington World of Adventures Resort</td>
                  <td>30 tickets per day</td>
                </tr>
                <tr>
                  <td>Thorpe Park Resort</td>
                  <td>30 tickets per day </td>
                </tr>
                <tr>
                  <td>The LEGOLAND® Windsor Resort </td>
                  <td>30 tickets per day </td>
                </tr>
                <tr>
                  <td>Warwick Castle</td>
                  <td>30 tickets per day</td>
                </tr>
                <tr>
                  <td  >
                    Shrek's Adventure London,<br />
                    Madame Tussauds London, <br />
                    London Dungeons,<br />
                    SEA LIFE London Aquarium <br />
                    & the lastminute.com <br />
                    London Eye<br />
                  </td>
                  <td>125 tickets per day, per site</td>
                </tr>
                <tr>
                  <td>
                    SEA LIFE Centre Birmingham,<br />
                    Blackpool, Brighton, <br />
                    Great Yarmouth, Hunstanton, <br />
                    Loch Lomond, Manchester, <br />
                    Scarborough and Weymouth <br />
                  </td>
                  <td>75 tickets per day, per site</td>
                </tr>
                <tr>
                  <td>
                    LEGOLAND® Discovery Centre Manchester & Birmingham, <br />
                    Blackpool Dungeons, <br />
                    Blackpool Tower Eye, <br />
                    Blackpool Tower Circus, <br />
                    Madame Tussauds Blackpool, <br />
                    Edinburgh Dungeons, <br />
                    York Dungeons.<br />
                  </td>
                  <td>35 tickets per day, per site</td>
                </tr>
              </table>
              <p><strong>Table B - OFF PEAK, SUPER OFF PEAK, PEAK and SUPER PEAK dates </strong></p>
              <table>
                <tr>
                  <th colspan='6'>Alton towers resort</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed Dates</td>
                </tr>
                <tr >
                  <td colspan='6'>
                    3-31 January; 1-10,<br />
                    27-28 February; <br />
                    1-17 March; <br />
                    6-23, 27-30 November;<br />
                    4-7, 11-14, 24-26, 31 December 2023. <br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super Off-peak Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    21-23, 28-30 March; <br />
                    18-20, 25-27 April; <br />
                    2-4, 9-11, 16-18, 23-25 May; <br />
                    5-7, 12-14, 19-21, 26-28 September; <br />
                    3-5, 10-12 October; <br />
                    16 December 2023. <br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-3 January,<br />
                    11-26 February; <br />
                    20, 24, 27, 31 March; <br />
                    17, 21, 24, 28 April; <br />
                    5, 12, 15, 19, 22, 26 May;<br />
                    5-9, 12-16, 19-23, 26-30 June; <br />
                    3-7 July; <br />
                    4, 8, 11, 15, 18, 22, 25, 29 September; <br />
                    6, 9 October; <br />
                    15 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    18-19, 25-26 March;<br />
                    1-6, 11-16, 22-23 April;<br />
                    1, 6-8, 13-14, 20-21 May; <br />
                    4, 10-11, 17-18, 24-25 June;<br />
                    1-2, 8-21 July;<br />
                    9-10, 16-17, 23-24, 30 September;<br />
                    1, 13, 16-19 October;<br />
                    1-2, 24-26 November; <br />
                    1, 8, 17-23, 27-30 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super Peak Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    7-10, 29-30 April; <br />
                    27-31 May; 1-3 June; <br />22-31 July; <br />
                    1-31 August;<br />
                    1-3 September;<br />
                    7-8, 14-15, 20-31 October; <br />
                    3-5 November; <br />
                    2-3, 9-10 December 2023.<br />
                  </td>
                </tr>
              </table>
              <table>
                <tr>
                  <th colspan='6'>Chessington world of adventures resort</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-31 January; <br />
                    1-28 February; <br />
                    1-22, 28-29 March; <br />
                    31 October; <br />
                    6-30 November; <br />
                    1-31 December 2023. <br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    23-24, 27, 30 March; <br />
                    24-27 April; <br />
                    2-4, 8-11 May; <br />
                    11-15, 18-22, 25-29 September; <br />
                    2-6, 9-12 October; <br />
                    1-5 November 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    31 March; <br />
                    17-21, 28 April; <br />
                    5, 12, 22-25 May; <br />
                    7-9, 12-15, 19-22, 26-29 June;<br />
                    3-7 July; <br />
                    5-8 September;<br />
                    13, 16-19, 30 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    25-26 March; <br />
                    1-6, 11-16, 22-23, 29-30 April;<br />
                    1, 6-7, 13-19, 26 May; <br />
                    4-6, 10-11, 16-18, 23-25, 30 June; <br />
                    1-2, 8-31 July;<br />
                    1-11 August; <br />
                    3-4, 9-10, 16-17, 23-24 & 30 September; <br />
                    1, 7-8, 14-15, 20 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    7-10 April; <br />
                    20-21, 27-31 May;<br />
                    1-3 June; <br />
                    12-31 August;<br />
                    1-2 September; <br />
                    21-29 October 2023.<br />
                  </td>
                </tr>
              </table>
              <table>
                <tr>
                  <th colspan='6'>Thorpe park resort</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed Dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-31 January;<br />
                    1-28 February;<br />
                    1-23 March; 25-26 April;<br />
                    10, 16-17 May; 12-13, 19-20, 26-27 September; <br />
                    2-4, 9-10 October; <br />
                    1-31 November; <br />
                    1-31 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    7-30 March;<br />
                    24, 27 April;<br />
                    2, 4, 9, 11, 15, 22-23 May;<br />
                    11, 14, 18, 21, 25, 28 September;<br />
                    5, 12 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    24-26, 31 March;<br />
                    1-6, 11-23, 28 April;<br />
                    3, 5, 12-14, 18-21, 24-26 May; <br />
                    4-16, 19-23, 26-30 June;<br />
                    3-7, 10-14, 17-21 July;<br />
                    4-8, 10, 15, 17, 22, 24, 28 September;<br />
                    1, 8, 11, 16-19, 30 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak date</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    7-10, 29-30 April;<br />
                    1, 6-8, 27-31 May;<br />
                    1-3, 17-18, 24-25 June;<br />
                    1-2, 8-9, 15-16, 22-31 July;<br />
                    1-11 August;<br />
                    9, 16, 23, 30 September;<br />
                    7, 13, 15, 20, 31 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    12-31 August;<br />
                    1-3 September;<br />
                    14, 21-29 October 2023.<br />
                  </td>
                </tr>

              </table>
              <table>
                <tr>
                  <th colspan='6'>The Legoland&#174; windsor resort</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-31 January;<br />
                    1-10, 21-23, 27-28 February;<br />
                    1-3, 6-16 March; 26 April;<br />
                    3, 10, 17 May;<br />
                    26-27 September;<br />
                    4, 11 October;<br />
                    6-24, 27-30 November;<br />
                    1, 4-8, 11-15, 24-26, 31 December 2023.
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    20-23, 27-30 March;
                    18-20, 24-25, 27 April;
                    2, 4, 9, 11, 15-16, 18, 22-25 May;<br />
                    5-7, 11-14, 18-21, 25, 28 September;<br />
                    2-3, 5, 9-10, 12 October 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>11-20, 24-26 February;<br />
                    4-5, 17, 24, 31 March; 17, 21, 28 April;<br />
                    5, 12, 19, 26 May;<br />
                    5-9, 12-16, 19-23, 26-30 June;<br />
                    3-7, 10-14, 17-21 July;<br />
                    8, 15, 22, 29 September;<br />
                    6, 8, 13, 16-19 October;<br />
                    25-26 November;<br />
                    2-3, 9-10, 16-23, 27-30 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    18-19, 25-26 March;<br />
                    1-16, 22-23, 29 April;<br />
                    1, 6-8, 13-14, 20-21, 27-31 May;<br />
                    1-4, 10-11, 17-18, 24-25 June;<br />
                    1-2, 8-9, 15-16, 22-31 July;<br />
                    1-11 August;<br />
                    4, 9-10, 16-17, 23-24, 30 September;<br />
                    1, 7, 14-15, 20-31 October;<br />
                    1-2 November 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    30 April;<br />
                    12-31 August;<br />
                    1-3 September;<br />
                    3-5 November 2023.<br />
                  </td>
                </tr>
                </table>
                <table>
                <tr>
                  <th colspan='6'>Warwick castle resort</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    3-15 January;<br />
                    18 February;<br />
                    24-31 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1, 16-20, 23-27, 30-31 January;<br />
                    1-3, 6-10, 27-28 February;<br />
                    1-3, 6-10, 13-17, 20-24, 27-30 March;<br />
                    2-5, 9-12 May;<br />
                    5-7, 11-14, 18-21, 25-28 September;<br />
                    2-5, 9-12, 30-31 October;<br />
                    1-3, 6-10, 13-17, 20-23 November;<br />
                    4-7, 11-14 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    2, 21-22, 28-29 January;<br />
                    4-5, 11-17, 19-26 February; <br />
                    4-5, 11-12, 18-19, 25-26, 31 March; <br />
                    6, 17-28 April;<br />
                    15, 19, 22-26 May;<br />
                    4-9, 12-16, 19-23, 26-30 June; <br />
                    3-7 July;<br />
                    4, 8, 10, 15, 17, 22, 24, 29 September; <br />
                    1, 6, 8, 13, 16-19 October; <br />
                    4-5, 11-12, 18-19, 27-30 November; <br />
                    1, 8-10, 15 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-5, 11-16, 29 April; <br />
                    1, 6-8, 13-14, 16-18, 20-21, 27-31 May; <br />
                    1-3, 10-11, 17-18, 24-25 June; <br />
                    1-2, 8-28 July;<br />
                    9, 16, 23, 30 September;<br />
                    7, 14-15, 20, 22-26 October;<br />
                    24-26 November;<br />
                    2-3, 16-23 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    7-10, 30 April;<br />
                    29-31 July;<br />
                    1-31 August;<br />
                    1-3 September;<br />
                    21, 27-29 October 2023.<br />
                  </td>
                </tr>
                </table>
                <table>
                <tr>
                  <th colspan='6'>Shrek's adventure london, madame tussauds london, london dungeons, the lastminute.com london eye; sea life aquariums & centres; legoland® discovery centre manchester & birmingham; the dungeons - york, edinburgh & blackpool tower; the blackpool tower eye; and madame tussauds blackpool.</th>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Closed</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    <strong>Shrek's Adventure London, Madame Tussauds London, London Dungeons, SEA LIFE London Aquarium:</strong> 25 December 2023; <br /><br />
                    <strong>Sealife Centre Birmingham, Blackpool, Brighton, Great Yarmouth, Hunstanton, Loch Lomond, Manchester, Scarborough, Weymouth:</strong> 1 January, 24-26 December 2023;<br /><br />
                    <strong>the lastminute.com London Eye:</strong> 9-22 January, 25 December 2023;<br /><br />
                    <strong>Legoland® Discovery Centre Manchester & Birmingham:</strong> 1 January, 25-26 December 2023; <br /><br />
                    <strong>Blackpool Dungeons:</strong> 16-20, 23, 25, 27, 30-31 January, 1-3, 6-10, 23 February, 2, 9, 13, 20, 27 March, 11-30, 1-31 December 2023; <br /><br />
                    <strong>Blackpool Tower Eye:</strong> 1-31 January, 1-28 February, 1-31 March, 6, 10, 13, 17-30 November, 1-31 December 2023; <br /><br />
                    <strong>Blackpool Tower Circus:</strong> 1-31 January, 1-10, 20-24, 27-28 February, 1-3, 6-10, 13-17, 20-24, 27-31 March, 6, 10, 12-30 November, 1-31 December 2023; <br /><br />
                    <strong>Edinburgh Dungeons, York Dungeons:</strong> 1 January, 24-26 December 2023; <br /><br />
                    <strong>Madame Tussauds Blackpool:</strong> 2-6, 9-13, 16-20, 23-27, 30-31 January, 1-3, 6-10, 27-28 February, 6-10, 13-17, 20-24, 27-31 March, 13-17, 20-24, 27-30 November, 1, 4-8, 11-15, 18-22, 25 December 2023; <br /><br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    9-13, 16-20,<br />
                    23-27, 30-31 January;<br />
                    1-3 February; <br />
                    6-9, 13-16, 20-23 March; <br />
                    24-27 April; <br />
                    1-4, 8-11, 15-16 May; <br />
                    19-21, 26-28 September;<br />
                    3-5, 10-12 October;<br />
                    13-16, 19-22, 25-28 November;<br />
                    1-4 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Off-peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    14-15, 21-22, 28-29 January;<br />
                    4-8 February;<br />
                    1-5, 10-12, 17-19, 23-29 March;<br />
                    17-23, 28-30 April;<br />
                    5-7, 12-14, 17-23 May;<br />
                    7-9, 12-16, 19-23, 26-30 June;<br />
                    11-15, 18, 22, 25, 29 September;<br />
                    2, 6, 9 October;<br />
                    6-9, 17-18, 23-24, 29-30 November;<br />
                    5-8, 11-12 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    3-8 January;<br />
                    15-17, 20-24, 27-28 February;<br />
                    30-31 March; 1-5, 12-14, 15 April;<br />
                    24-25 May;<br />
                    5-6, 10-11, 17-18, 24-25 June;<br />
                    1-21 July;<br />
                    6-8, 10, 17, 24, 30 September;<br />
                    1, 8, 13, 16-20, 30 October;<br />
                    1-2, 10-12 November;<br />
                    9-10 December 2023.<br />
                  </td>
                </tr>
                <tr>
                  <td colspan='6' className='tandc__heading'>Super peak dates</td>
                </tr>
                <tr>
                  <td colspan='6'>
                    1-2 January;<br />
                    9-14, 18-19, 25-26 February;<br />
                    6-11, 15 April;<br />
                    26-31 May; 1-4 June;<br />
                    22-31 July; 1-31 August;<br />
                    1-5, 9, 16, 23 September;<br />
                    7, 14-15, 21-29, 31 October;<br />
                    3-5 November;<br />
                    13-31 December 2023.<br />
                  </td>
                </tr>
                </table>
            </li>
            <li><p>2024 allocations will be updated on these terms and conditions and available from 1st January 2024 once annual calendars are confirmed. The dates listed as OFF PEAK, SUPER OFF PEAK, PEAK and SUPER PEAK may be amended from time to time, in which case these terms and conditions will be updated accordingly.</p></li>
            <li>
              <p>
                The offer is subject to the attractions' annual opening calendar. Please check all opening dates and times before your visit on the attraction's individual websites. All dates are subject to change.
              </p>
              <p>
                <strong>The Blackpool Tower Eye</strong> will be closed for maintenance during the winter period. Please check opening times via website <a href='https://www.theblackpooltower.com/plan/before-you-visit/opening-hours' target='_blank' rel='noopener noreferrer'>https://www.theblackpooltower.com/plan/before-you-visit/opening-hours</a>. Opening is subject to weather conditions. Opening times at all Blackpool attractions may vary during the winter season.
              </p>
              <p>
                <strong>The Lastminute.com London Eye</strong> is closed for a limited period in January every year for annual maintenance.
              </p>
            </li>
            <li><p>The offer and/or unique code (where applicable) can only be used as stipulated. The offer and/or unique code have no cash value and are non-transferable/not for resale. All offers and/or unique codes are automatically void if sold, offered for sale or if any attempt or offer is made to transfer the offer and/or unique code for value.  </p></li>
            <li><p>Offer is only valid on promotional packs which feature the UP TO 50% promotion including: Cadbury Snowy Fingers, Cadbury Festive Friends, Oreo Spooky, Cadbury Small Selection Box, Cadbury Medium Santa Selection Box, Cadbury Skeleton Fingers.</p></li>
            <li><p>This offer is not valid at secondary attractions and events including, but not limited to; The Alton Towers Dungeon, Alton Towers Spa, Extraordinary Golf, Treetop Quest, the Waterpark, Scarefest Halloween Event Mazes at Alton Towers Resort, Fright Nights Mazes at Thorpe Park Resort, The Castle Dungeon and Dragon Slayer at Warwick Castle, , LATES at The Dungeons and SEA LIFE London Aquarium, The Blackpool Tower Ballroom, Bar 380, The Fifth Floor or Dino Golf at The Blackpool Tower Eye. </p></li>
            <li><p>This offer can only be used for standard single day entrance tickets and cannot be used for any combination, fastrack/priority/early ride time, 2-day or short break tickets including, but not limited to; Merlins Magical London Ticket at London Attractions, Multi-Attraction tickets at The Blackpool Tower Attractions, Merlin Annual Pass, Season Pass. </p></li>
            <li><p>This offer is not valid for accommodation including but not limited to; The Alton Towers Hotel, CBeebies Land Hotel, Splash Landings Hotel, Enchanted Village (Woodland Lodges and Luxury Treehouses) and Stargazing Pods at the Alton Towers Resort, Safari Hotel, Azteca Hotel and Explorer Glamping at Chessington World of Adventures Resort, Tower Suites and Knight's Village at Warwick Castle, Thorpe Shark Cabins at Thorpe Park Resort or the LEGOLAND® Resort Hotel and The LEGOLAND® Castle Hotel at the LEGOLAND® Windsor Resort. Additional costs apply. Please check each attraction's website for additional costs.</p></li>
            <li><p>This offer is not valid for transport or car parking. Some attractions operate a chargeable car parking policy including, but not limited to; Chessington World of Adventures Resort, The LEGOLAND® Windsor Resort, Thorpe Park Resort, Alton Towers Resort and Warwick Castle.</p></li>
            <li><p>The offer and/or unique code which form part of this offer remains the property of Merlin Entertainments at all times and as such Merlin Entertainments reserves the right to take possession of the offer and/or unique code at any time. </p></li>
            <li><p>Size, height, weight, age restrictions and medical warnings apply to certain rides and/or attractions.</p></li>
            <li><p>Visitors under 12 years must be accompanied by a person aged 18 years or over at Thorpe Park Resort, Warwick Castle, Chessington World of Adventures Resort and Alton Towers Resort. Visitors 14 years and under must be accompanied by a person aged 18 years and over at the LEGOLAND® Windsor Resort, SEA LIFE Aquariums and Centres (Manchester, Blackpool, Brighton, Great Yarmouth, Weymouth, Scarborough, Loch Lomond and Hunstanton) Madame Tussauds Blackpool and The Blackpool Tower Eye and Circus. Visitors 15 years and under must be accompanied by an adult over the age of 18 at SEA LIFE Birmingham, The Dungeons (Edinburgh, York and Blackpool). Visitors 16 years and under must be accompanied by an adult aged 18 and over at The London Eye, Shrek's Adventure! London, SEA LIFE London, Madame Tussauds London and The London Dungeon. No person aged 18 and over can enter the LEGOLAND® Discovery Centres (Manchester or Birmingham) without an accompanying child/ children aged 17 years or younger. </p></li>
            <li><p>For the purpose of admissions an adult is classified as 12 years of age and over at the Alton Towers Resort and Chessington World of Adventures Resort. An adult is classified as a person 15 years of age and over at SEA LIFE Aquariums and Centres (Manchester, Blackpool, Brighton, Great Yarmouth, Weymouth, Scarborough, Loch Lomond and Hunstanton), Madame Tussauds Blackpool, The Blackpool Tower Dungeon, The Blackpool Tower Eye and The Blackpool Tower Circus. An adult is classified as a person 16 years of age and over at The London Eye, Shrek's Adventure! London, Madame Tussauds London, SEA LIFE London Aquarium, The Dungeons (London, York and Edinburgh). An adult is classified as a person 18 years of age and over at LEGOLAND® Discovery Centres (Manchester or Birmingham). Some attractions operate an individual ticket price which is the same for both adults and children for the purpose of admissions. This includes but is not limited to; The LEGOLAND® Windsor Resort, Warwick Castle, Thorpe Park Resort, LEGOLAND® Discovery Centre Birmingham and SEA LIFE Birmingham.</p></li>
            <li>
            <p>Under 3's go free at most Merlin attractions apart from the below exceptions. For full details please check the attraction websites.</p>
            
            <table>
              <thead><tr><th>Attraction</th><th>Restriction</th></tr></thead>
              <tbody>
              <tr><td><ul><li>Madame Tussauds London</li><li>SEA LIFE London</li><li>Shrek's Adventure! London</li><li>The London Eye</li></ul></td><td>Under 2's go free</td></tr>
              
              </tbody>
            </table>

            <table>
              <thead><tr><th>Attraction</th><th>Restriction</th></tr></thead>
              <tbody>
                <tr><td><ul><li>LEGOLAND® Windsor Resort</li></ul></td><td>Under 90cm go free</td></tr>
              </tbody>
            </table>

            <table>
              <thead><tr><th>Attraction</th><th>Restriction</th></tr></thead>
              <tbody>
              <tr><td>
                <p>All Merlin attractions, except:</p>
                <ul><li>Madame Tussauds London</li>
                <li>SEA LIFE London</li>
                <li>Shrek's Adventure! London</li>
                <li>London Eye</li>
                <li>LEGOLAND® Windsor Resort</li></ul>
              </td><td>Under 3's go free</td></tr>
              </tbody>
            </table>
            </li>
            <li><p>All of the attractions reserve the right, in their absolute discretion, to refuse entry and to close and/or alter all or any part of the facilities including closure of rides and/or attractions and removal of figures for technical, operational, health and safety or other reasons including overcapacity. </p></li>
            <li><p>Features, rides, attractions and/or creatures shown on the website or communications are not necessarily available at every attraction. Please check ahead of your visit to avoid disappointment. </p></li>
            <li>
              <p>All attraction information is subject to change and entry into any participating attraction is subject to the individual attraction's terms and conditions or entry. Please check the attraction website(s) below before your visit for the latest information;</p>
              <br/>
              <a href='https://altontowers.com' rel="noopener noreferrer" target='_blank'>www.altontowers.com</a><br />
              <a href='https://THORPEPARK.com' rel="noopener noreferrer" target='_blank'>www.THORPEPARK.com</a><br />
              <a href='https://chessington.com' rel="noopener noreferrer" target='_blank'>www.chessington.com</a><br />
              <a href='https://LEGOLAND.co.uk' rel="noopener noreferrer" target='_blank'>www.LEGOLAND.co.uk</a><br />
              <a href='https://LEGOLANDDiscoveryCentre.co.uk' rel="noopener noreferrer" target='_blank'>www.LEGOLANDDiscoveryCentre.co.uk</a><br />
              <a href='https://visitsealife.com' rel="noopener noreferrer" target='_blank'>www.visitsealife.com</a><br />
              <a href='https://thedungeons.com' rel="noopener noreferrer" target='_blank'>www.thedungeons.com</a><br />
              <a href='https://madametussauds.com' rel="noopener noreferrer" target='_blank'>www.madametussauds.com</a><br />
              <a href='https://theblackpooltower.com' rel="noopener noreferrer" target='_blank'>www.theblackpooltower.com</a><br />
              <a href='https://sealifeweymouth.com' rel="noopener noreferrer" target='_blank'>www.sealifeweymouth.com</a><br />
              <a href='https://shreksadventure.com' rel="noopener noreferrer" target='_blank'>www.shreksadventure.com</a><br />
              <a href='https://warwick-castle.com' rel="noopener noreferrer" target='_blank'>www.warwick-castle.com</a><br />
              <a href='https://londoneye.com' rel="noopener noreferrer" target='_blank'>www.londoneye.com</a>.<br />
            </li>
            <li><p>In the event of circumstances outside the reasonable control of the Promoter, or otherwise where fraud, abuse, and/or an error (human or computer) affects or could affect the proper operation of this promotion, and only where circumstances make this unavoidable, the Promoter reserves the right to cancel or amend the promotion or these offer terms and conditions, at any stage, but will always endeavour to minimize the effect to participants in order to avoid undue disappointment.</p></li>
            <li><p>If any of these clauses should be determined to be illegal, invalid or otherwise unenforceable then it shall be severed and deleted from these terms and conditions and the remaining clauses shall survive and remain in full force and effect.</p></li>
            <li><p>The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person's negligence) in connection with this promotion or accepting or using the prize/gift, except for any liability which cannot be excluded by law (including personal injury, death and fraud) in which case that liability is limited to the minimum allowable by law.</p></li>
            <li><p>These terms and conditions and any related disputes are subject to interpretation under the laws of England and Wales, and to the jurisdiction of the courts of England and Wales unless your residential address is in Scotland, when Scottish law applies or in the Republic of Ireland, when Irish law applies.</p></li>
          </ol>
        </> }
      
        <p><strong>PROMOTER:</strong> Mondelez Europe Services GmbH - UK Branch, Sanderson Road, Uxbridge, UB8 1DH.</p>
        <p>LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, DUPLO and LEGOLAND are trademarks of the LEGO Group. &#169; {currentYear} The LEGO Group.</p>
        <p>&#169; {currentYear} DreamWorks Animation L.L.C. </p>
        <p>&#169;The London Eye {currentYear} all rights reserved. Company. </p>
        <p>Any images shown of Madame Tussauds depict wax figures created and owned by Madame Tussauds.</p>

      </div>

    </div>
  </section>
  </>;
}